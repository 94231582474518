<template>
  <el-container>
    <Header pageName="表单收集"></Header>
    <el-main>
      <el-form class="el-form-search" label-width="90px">
        <el-form-item label="关键词：">
          <el-input v-model="keyworks" placeholder="请输入帮助标题" size="small"></el-input>
        </el-form-item>
        <el-form-item label="渠道来源：">
          <el-select v-model="channel" size="small" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="官网" :value="1"></el-option>
            <el-option label="公众号" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请日期：">
          <el-date-picker v-model="create_time" size="small" type="daterange" value-format="timestamp" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label=" " label-width="20px">
          <el-button type="primary" size="small" @click="search">搜索</el-button>
          <el-button plain size="small" @click="cancelSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="name" label="客户姓名" align="center"></el-table-column>
        <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
        <el-table-column prop="industry" label="所属行业" align="center"></el-table-column>
        <el-table-column prop="address" label="所在区域" align="center"></el-table-column>
        <el-table-column prop="requirement_description" label="需求描述" align="center"></el-table-column>
        <el-table-column prop="channel" label="渠道来源" align="center">
          <template v-slot="{ row }">{{ row.channel == 1 ? '官网' : '公众号' }}</template>
        </el-table-column>
        <el-table-column prop="create_time" label="申请日期" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
        </el-table-column>
      </el-table>
      <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </el-main>
  </el-container>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import Header from './components/header';
export default {
  components: {
    Paging,
    Header
  },
  data() {
    return {
      page: 1,
      rows: 10,
      list: [],
      total_number: 0,
      keyworks: '',
      create_time: '',
      channel: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      303839
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    search() {
      this.page = 1;
      this.rows = 10;
      this.getList();
    },
    cancelSearch() {
      this.page = 1;
      this.rows = 10;
      this.name = '';
      this.classification_id = '';
      this.getList();
    },
    getList() {
      let data = {
        page: this.page,
        rows: this.rows,
      };
      if (this.channel) data.channel = this.channel;
      this.$axios.post(this.$api.admin.formData, data).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  .el-main {
    background: #fff;
    .el-form {
      margin-top: 10px;
    }
  }
}
</style>